<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shield"></i> 安全巡检 | 记录查询</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <!-- <form id="fm_export" method="post" action="/safe/rec/export" target="_blank" style="display: inline-block;">
            <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
            <input type="hidden" name="CONF_ID" v-model="grid.sea.CONF_ID" />
            <input type="hidden" name="RESULT" v-model="grid.sea.RESULT" />
            <input type="hidden" name="DTS" v-model="grid.sea.DTS" />
            <input type="hidden" name="DTE" v-model="grid.sea.DTE" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.RESULT" @change="filter" clearable placeholder="请选择结果">
                <el-option label="正常" value="9" />
                <el-option label="异常" value="1" />
              </el-select>
              <el-select v-model="grid.sea.CONF_ID" @change="filter" clearable placeholder="请选择项目"
                style="margin-left: 5px">
                <el-option :label="it.NAME" :value="it.ID" v-for="it in ls_conf" :key="it.ID" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="CONF_NAME" label="项目名称" width="200" />
              <el-table-column prop="DESCRIPTION" label="问题描述" width="200" />
              <el-table-column prop="RET_TXT" label="结果" width="80" align="center" />
              <el-table-column prop="FREQUENCY_TXT" label="周期" width="60" align="center" />
              <el-table-column prop="SUB_NAME" label="提交人" width="80" align="center" />
              <el-table-column label="日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}</template>
              </el-table-column>
              <el-table-column />
            </wgrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
        },
        ls: [],
        total: 0,
        loading: false
      },
      ls_conf: []
    }
  },
  created() {
    this.getConf();
    this.getList();
  },
  methods: {
    getConf() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/SAFE/ConfigApi/GetList",
        data: { PARENT_ID: 0 },
        completed(its) {
          self.ls_conf = its
        }
      })
    },
    filter() {
      if (this.grid.date_range != null && this.grid.date_range.length > 1) {
        this.grid.sea.DTS = this.grid.date_range[0];
        this.grid.sea.DTE = this.grid.date_range[1];
      } else {
        this.grid.sea.DTS = this.grid.sea.DTE = "";
      }
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/SAFE/RecApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('CONF_ID', this.grid.sea.CONF_ID || '')
      formData.append('RESULT', this.grid.sea.RESULT || '')
      formData.append('DTS', this.grid.sea.DTS || '')
      formData.append('DTE', this.grid.sea.DTE || '')
      this.whale.remote.getExport({
        url: "/api/School/SAFE/Rec/Export",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '导出安全巡检记录查询.xls')
          self.grid.loading = false;
        }
      })
    }
  }
}
</script>

